import { noop } from '@vueuse/core';
import { RepeatWrapping, SRGBColorSpace } from 'three';
import { defineComponent, inject, onMounted, provide } from 'vue';

import { LoaderEvent } from '@resn/gozer-loading';
import { useLoaderComplete } from '@resn/gozer-vue/loading';

export const GlobalAssetsKey = Symbol('GlobalAssets');

export const useGlobalAssets = (onLoaded = noop) => {
    const provider = inject(GlobalAssetsKey);

    if (!provider) {
        throw new Error('GlobalAssets provider not found');
    }

    onMounted(() => {
        if (provider.loader.state === 'complete') onLoaded({ data: provider.loader.data });
        provider.loader.once(LoaderEvent.LOAD_COMPLETE, onLoaded);
    });

    return provider;
};

export const GlobalAssets = defineComponent({
    props: {
        assets: {
            type: Object,
            default: () => ({}),
        },
    },

    async setup(props) {
        const onLoaded = ({ data }) => {
            provider.assets = data;

            Object.values(data).forEach((asset) => {
                if (asset.isTexture) {
                    asset.colorSpace = SRGBColorSpace;
                    asset.wrapS = asset.wrapT = RepeatWrapping;
                    asset.flipY = false;
                    asset.needsUpdate = true;
                }
            });
        };

        const loader = useLoaderComplete(
            {
                'card-psa': '/glb/slab-psa-etc1s@lg.glb',
                'card-cgc': '/glb/slab-cgc-etc1s@lg.glb',
                'card-bgs': '/glb/slab-bgs-etc1s@lg.glb',

                envMap: '/textures/env-map/env-map-6@lg.webp#texture',
                matcapDiffuse: '/textures/matcap/slab-diffuse-etc1s.ktx2',

                // Card textures
                'card-mask-psa': '/textures/card/card-mask-psa-etc1s.ktx2',
                'card-mask-cgc': '/textures/card/card-mask-cgc-etc1s.ktx2',
                'card-mask-bgs': '/textures/card/card-mask-bgs-etc1s.ktx2',

                'card-outline-psa': '/textures/card/outline-mask-psa-etc1s.ktx2',
                'card-outline-cgc': '/textures/card/outline-mask-cgc-etc1s.ktx2',
                'card-outline-bgs': '/textures/card/outline-mask-bgs-etc1s.ktx2',

                noiseWhite: '/textures/noise/white-noise-etc1s.ktx2',
                noise1: '/textures/noise/noise.png#texture',
                matcapRevised6: '/textures/matcap/matcap-revised_6-etc1s.ktx2',
                pNoise: '/textures/noise/pnoise1-etc1s.ktx2',
                ...props.assets,
            },
            onLoaded
        );

        const provider = {
            assets: null,
            loader,
        };

        provide(GlobalAssetsKey, provider);
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }
        return null;
    },
});
